import React, { useEffect, useRef, useState } from "react";
import { changeFirstWordsColor } from "../utils/helpers";
import { useMediaQuery } from "react-responsive";
import { BackgroundVideoPlayer } from "./BackgroundVideoPlayer";
import { CLOUD_FRONT_URL } from "../config/constants";

const Slide2CardContainer = ({ cards }) => {
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const containerRef = useRef(null);
  const cardRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    if (isMobile) return;

    if (containerRef && cardRef) {
      setContainerWidth(containerRef.current.offsetWidth);
      setCardWidth(cardRef.current.offsetWidth);
    }

    const setWidth = () => {
      setContainerWidth(containerRef?.current?.offsetWidth || 0);
      setCardWidth(cardRef?.current?.offsetWidth || 0);
    };
    window.addEventListener("resize", setWidth);

    return () => window.removeEventListener("resize", setWidth);
  }, [containerRef?.current, cardRef?.current]);

  return (
    <div
      ref={containerRef}
      className="flex relative xl:gap-12 h-auto xl:h-[388px] flex-col xl:flex-row"
    >
      {cards.map((card, idx) => {
        return (
          <>
            {!isMobile ? (
              <div
                key={`${card.heading}-${idx}`}
                ref={cardRef}
                className="h-full w-full min-w-[242px] overflow-hidden rounded-lg border border-border-primary relative last:mr-6 xl:last:mr-0"
              >
                <div className="absolute w-full h-full bg-surface-modal rounded-[3px] opacity-80 select-none pointer-events-none" />
                <div className="absolute w-full h-full bg-modal-glass rounded backdrop-blur-[6px] select-none pointer-events-none" />
                <div className="absolute w-full h-full bg-noise opacity-80 rounded select-none pointer-events-none" />

                <div className="relative z-10 w-full h-full rounded-lg border border-border-primary">
                  <div
                    className="absolute bottom-0 left-0 h-[603px] rounded-inherit select-none pointer-events-none"
                    style={{
                      width: containerWidth,
                      transform: `translateX(-${idx * cardWidth}px)`,
                    }}
                  >
                    <BackgroundVideoPlayer
                      webMVideo={`${CLOUD_FRONT_URL}/videos/rfxh.webm`}
                      mp4Video={`${CLOUD_FRONT_URL}/videos/rfxh.webm`}
                      videoOptions={{
                        className: "w-full h-full object-cover rounded-inherit",
                      }}
                    />
                  </div>

                  <div className="z-20 relative rounded-inherit">
                    <p
                      className="p-6 font-bold tracking-[1.28px] text-greyscale-text-primary text-start rounded-t-inherit border-b border-border-primary"
                      dangerouslySetInnerHTML={changeFirstWordsColor(
                        card.heading,
                        card.wordsToChangeColor
                      )}
                      style={{ fontVariant: "small-caps" }}
                    />
                    <p className="py-8 px-6 text-sm leading-6 font-medium text-greyscale-text-primary tracking-[1.12px] text-start">
                      {card.subheading}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={`${card.heading}-${idx}`}
                className="h-full w-full min-w-[242px] relative last:mr-6 xl:last:mr-0"
              >
                <div className="relative z-10 w-full h-full border border-border-primary">
                  <div className="z-20 relative rounded-inherit">
                    <p
                      className="p-6 font-bold tracking-[1.28px] text-greyscale-text-primary text-start rounded-t-inherit border-b border-border-primary"
                      dangerouslySetInnerHTML={changeFirstWordsColor(
                        card.heading,
                        card.wordsToChangeColor
                      )}
                      style={{ fontVariant: "small-caps" }}
                    />
                    <p className="py-8 px-6 text-sm leading-6 font-medium text-greyscale-text-primary tracking-[1.12px] text-start">
                      {card.subheading}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default Slide2CardContainer;
