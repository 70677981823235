export const socialLink = {
  TELEGRAM_URL: "https://t.me/rfx_exchange",
  TWITTER_URL: "https://x.com/rfx_exchange",
  DISCORD_URL: "https://discord.gg/KQ62z2m3JU",
  GITHUB_URL: "https://github.com/relative-finance",
  MIRROR_URL: "https://mirror.xyz/0x12Daf0085914D71fEA20Ccf66cb4704b67498383",
};

const rfx_base_url = "https://alpha.rfx.exchange";

export const TRADE_ON = `${rfx_base_url}/trade`;
export const LAUNCH_APP = `${rfx_base_url}`;
export const CREATE_NEW_POOL = `${rfx_base_url}/permissionless`;

export const READ_DOCS =
  "https://mirror.xyz/0x12Daf0085914D71fEA20Ccf66cb4704b67498383";
